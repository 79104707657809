<template>
  <div>
    <base-page-header title="Training Videos Editor" back-link="training_videos">
      <template #buttons>
        <div class="d-flex justify-content-end">
          <base-button title="Upload Video" action="primary" @click-btn="ui.showUploadModal = true" />
        </div>
      </template>
    </base-page-header>
    <base-page-content>
      <template #content>
        <div class="table-block">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 5%">Order</th>
                <th style="width: 50%">Videos</th>
                <th style="width: 10%">Visibility</th>
                <th style="width: 10%">Uploaded on</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody v-if="ui.loading || videos.length == 0">
              <tr>
                <td colspan="5" class="text-center">
                  <page-spinner v-if="ui.loading" />
                  <span class="no-result" v-else>Training Video not found</span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(video, index) in videos" :key="video.id">
                <td>#{{ index + 1 }}</td>
                <td>
                  <div class="d-flex video-info">
                    <video ref="video" :src="video.videoUrl" class="video-player" width="200" controlsList="nodownload">
                    </video>
                    <div class="video-info-body">
                      <div class="video-info-body__title">
                        {{ video.title }}
                      </div>
                      <div class="video-info-body__description">
                        {{ video.description }}
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ video.privacy | fcCapitalString }}</td>
                <td>{{ formatDate(video.createdAt, 'MMM D, Y') }}</td>
                <td>
                  <div class="d-flex">
                    <button class="btn btn-outline-dark" @click="editVideo(video)">Edit</button>
                    <button
                      class="btn btn-outline-dark btn-control"
                      @click="updateSequence(video.id, 'up')"
                    >
                      <img
                        src="@/assets/icons/icon-arrow arrow-up.svg"
                        alt=""
                      />
                    </button>
                    <button
                      class="btn btn-outline-dark btn-control"
                      @click="updateSequence(video.id, 'down')"
                    >
                      <img
                        src="@/assets/icons/icon-arrow arrow-down.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </base-page-content>
    <transition name="component-fade" mode="out-in">
      <upload-training-video-modal
        v-if="ui.showUploadModal"
        @created="createdVideo"
        @close="ui.showUploadModal = false"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
        <edit-training-video-modal v-if="ui.showEditModal" @close="ui.showEditModal = false" :video="selectedVideo" @updated="editVideoModelClose" />
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import BasePageContent from "../../../components/BasePageContent.vue";
import BasePageHeader from "../../../components/BasePageHeader.vue";

export default {
  components: {
    BasePageHeader,
    BasePageContent,
    UploadTrainingVideoModal: () => import("./modals/UploadTrainingVideoModal.vue"),
    PageSpinner: () => import("../../../components/pageSpinner"),
    EditTrainingVideoModal: () => import('./modals/EditTrainingVideoModal.vue'),
    BaseButton,
  },
  name: "TrainingVideosEditor",
  data() {
    return {
      ui: {
        showUploadModal: false,
        loading: false,
        showEditModal: false,
      },
      videos: [],
      selectedVideo: {}
    };
  },
  filters: {
    fcCapitalString: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    }
  },
  beforeMount() {
    this.getVideos();
  },
  methods: {
    getVideos() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/training-videos?query=&offset=0&limit=20")
        .then((res) => {
          this.videos = res.data.data;
          this.ui.loading = false;
        })
        .catch(() => {
          this.ui.loading = false;
          this.pushAlert(
            "error",
            "Something went wrong"
          )
        });
    },
    updateSequence(id, sequence) {
      this.ui.loading = true;
      let formData = new FormData();
      formData.append('action', sequence);

      this.$http
        .post(`/api/v1/training-video/${id}/update-sequence`, formData)
        .then((res) => {
          this.pushAlert("success", res.data.message);
          this.getVideos();
        });
    },
    createdVideo() {
      this.ui.showUploadModal = false;
      this.getVideos();
    },
    editVideo(video) {
      this.selectedVideo = video;
      this.ui.showEditModal = true;
    },
    editVideoModelClose() {
      this.ui.showEditModal = false;
      this.getVideos();
    }
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 10px;
}
.page-content {
  padding-top: 20px;
}
.table-block {
  .table {
    thead {
      tr {
        th {
          color: #000000;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 13px;
          opacity: 1;
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
    tbody {
      tr {
        vertical-align: top;
        td {
          vertical-align: top;
          &:not(:first-child) {
            padding-left: 0;
          }

          .btn-control {
            height: 32px;
            width: 32px;
            border-radius: 8px;
            opacity: 1;
            border: 1px solid #8a8a8a;
            img {
              opacity: 1;
            }
          }

          .btn-outline-dark {
            height: 32px;
            border: 1px solid #8a8a8a;
            border-radius: 8px;
            opacity: 1;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.video-info {
  max-width: 60%;
  white-space: initial;
  img {
    min-width: 150px;
    min-height: 90px;
    background-color: #e6e6e6;
  }
  &-body {
    margin-left: 16px;
    &__title {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 6px;
    }
    &__description {
      color: rgba($color: #000000, $alpha: 0.7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}
</style>
